import { environment as rivalEnvironment } from './environment.rival';

export const environment = {
  ...rivalEnvironment,
  isAffiliated: true,

  rivalName: "newvegas",
  casinoId: 263,
  GA_TRACKING: "G-2PDP3MWCW6",

  rivalChatGroupName: "NewVegas",
  prettyName: "NewVegas",
  contactEmail: "support@newvegas.vip",
  docsEmail: "documents@newvegas.email",

  publicPromotions: [
    {id: 1, name: "400", state: "eligible", details: "welcome", imgPath: "assets/icons/welcome_promo.png"},
    {id: 2, name: "cashback", state: "eligible", details: "welcome", imgPath: "assets/icons/cashback_promo.png"},
    {id: 3, name: "raffle", state: "eligible", details: "weekly", imgPath: "assets/icons/raffle_promo.png"},
    {id: 4, name: "cashtravaganza", state: "eligible", details: "weekly", imgPath: "assets/icons/cashtra_promo.png"},
    {id: 5, name: "comppoints", state: "eligible", details: "weekly", imgPath: "assets/icons/comppoint_promo.png"}
  ],

  legalData: {
    withdrawalTime: 14,
    minDepositAmount: 5,
    affTerms: true
  },

  //SEO
  metaDescription: "Play Online casino games at New Vegas Casino! Win free spins, earn no deposit bonus, take welcome bonus and win real cash at premium casino games. Gamble at table games & bet with live dealers to double your money immediately. Make secure transactions and fast payments even in crypto & bitcoin.",
  logoName: "newvegas-casino-online-casino-logo.png",
  logoAltText: "Official logo of New Vegas Casino, done in rich golden script, which represents the finest online gaming platform for premium gaming pleasure using a vast array of slot machines, table games, and live dealer options. Enjoy safe and VIP online gambling only with New Vegas Casino."

};


